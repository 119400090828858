import * as React from 'react';
import theme from '../theme';

const cellStyle = {
  textAlign: 'left',
  width: 'auto',
  padding: '12px !important',
  textTransform: 'capitalize',
  fontSize: 13,
  color: theme.colors.gray[600],
  p: { fontSize: 13 },
};
const Cell = ({ isHeader = false, children, styles, onClick, className, span = 1, index }) => {
  return isHeader ? (
    <th
      onClick={onClick}
      css={{
        ...cellStyle,
        ...styles,
        color: theme.colors.black,
        textTransform: 'uppercase',
        // paddingLeft: index === 0 ? '24px  !important' : '24px  !important',
      }}
      className={className}
    >
      <h4
        css={{
          fontSize: 13,
        }}
      >
        {children}
      </h4>
    </th>
  ) : (
    <td
      colSpan={span}
      onClick={onClick}
      css={{
        ...cellStyle,
        ...styles,
        // paddingLeft: index === 0 ? '24px  !important' : '24px  !important',
        a: { width: '100%', height: '100%', display: 'block' },
      }}
      className={className}
    >
      {children}
    </td>
  );
};

export default Cell;
