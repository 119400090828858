import axios from 'axios';

// our network system powered by the Olympian of god of transport

const baseUrl = 'https://api.withbrank.com';

export function hermes({ data, url, method = 'POST' }) {
  const requestURL = `${baseUrl}${url}`;

  return axios({
    data,
    url: requestURL,
    method,
  });
}
