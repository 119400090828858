import 'regenerator-runtime/runtime';
import React from 'react';
import brank from 'brank-connect';
import GlobalStyles from './theme/globalStyle';
import Logo from './brank-logo.png';
import Button from './components/Button';
import DataTable from './components/DataTable';
import { hermes } from './hermes';
import theme from './theme';
import { format } from 'date-fns';

function formatMoney(value = 0) {
  let number;
  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('Please pass in a string or number to this function');
  }
  number = typeof value === 'string' ? Number(value) : value;

  return number
    .toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
    .replace('$', '')
    .replace('US', '');
}

export function truncateString(fileName = '', length = 20, extension = '...') {
  return `${fileName.slice(0, length)}.${extension}`;
}

const accountColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    styles: {
      width: '40px',
      outline: '1px dotted red',
    },
  },

  {
    title: 'Account Name',
    dataIndex: 'name',
    key: 'name',
    render: (name) => {
      return <p css={{ fontSize: '12px !important' }}>{name}</p>;
    },
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
    key: 'account_number',
  },

  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },

  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (balance) => {
      return <p>{formatMoney(balance)}</p>;
    },
  },
];

const balanceColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },

  {
    title: 'Account Id',
    dataIndex: 'account_id',
    key: 'account_id',
  },

  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },

  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (balance) => {
      return <p>{formatMoney(balance)}</p>;
    },
  },
];

const transactionColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },

  {
    title: 'Date',
    dataIndex: 'transaction_date',
    key: 'transaction_date',
    render: (date) => {
      return <p css={{ width: '100px' }}>{format(new Date(date), 'MMM dd, yyyy')}</p>;
    },
  },

  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description) => {
      return <p css={{ fontSize: '12px !important' }}>{truncateString(description, 30)}</p>;
    },
  },

  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => {
      return <p>{formatMoney(amount)}</p>;
    },
  },

  {
    title: 'Type',
    dataIndex: 'direction',
    key: 'direction',
    render: (type) => {
      const colors = {
        credit: {
          bg: theme.colors.green[100],
          fg: theme.colors.green[600],
        },

        debit: {
          bg: theme.colors.red[100],
          fg: theme.colors.red[600],
        },
      };

      return (
        <div
          className="tag"
          css={{
            backgroundColor: colors[type].bg,
            color: colors[type].fg,
          }}
        >
          {type}
        </div>
      );
    },
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      const colors = {
        success: {
          bg: theme.colors.blue[100],
          fg: theme.colors.blue[600],
        },

        failed: {
          bg: theme.colors.rose[100],
          fg: theme.colors.rose[600],
        },
      };

      return (
        <div
          className="tag"
          css={{
            backgroundColor: colors[status].bg,
            color: colors[status].fg,
          }}
        >
          {status}
        </div>
      );
    },
  },
];

const App = () => {
  const [linked, setLinked] = React.useState(false);
  const [data, setData] = React.useState({});
  const [show, setShow] = React.useState({
    accounts: false,
    transaction: false,
    balance: false,
  });

  const [loading, setLoading] = React.useState('');

  async function loadSampleData() {
    try {
      const { data } = await hermes({
        url: '/sample-product-data',
        method: 'GET',
      });

      setData(data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(loadSampleData, []);

  const brankInstance = React.useMemo(() => {
    const b = brank({
      key: 'BrankSandbox-3486263726376256356',
      onSuccess: () => setLinked(true),
      env: 'sandbox',
    });

    b.setup();

    return b;
  });

  function toggleView(view) {
    setLoading(view);
    setTimeout(() => {
      setShow({
        ...show,
        [view]: true,
      });
      setLoading('');
    }, 300);
  }

  return (
    <>
      <GlobalStyles />

      <div className="container-large">
        <div className="logo">
          <img src={Logo} />
        </div>

        <h1 className="heading header">Get started with Brank</h1>
        {!linked && (
          <>
            <p className="sub-header">
              An example application that outlines an end-to-end integration with Brank
            </p>
            <Button className="btn-large" action={() => brankInstance.open()}>
              Connnect with The Boring Company
            </Button>
          </>
        )}

        {linked && (
          <>
            <p className="sub-header">
              Success! You just created a <span className="tag">LINK</span> by linking your account.
            </p>
            <div className="card">
              <div className="data-table">
                <div className="header heading">Accounts</div>
                <div className="routes">
                  <div className="route">https://sandbox.withbrank.com/accounts</div>
                  <Button
                    isLoading={loading === 'accounts'}
                    action={() => toggleView('accounts')}
                    size="sm"
                  >
                    Get Accounts
                  </Button>
                </div>

                {show.accounts && <DataTable columns={accountColumns} data={data?.accounts} />}
              </div>

              <div className="data-table">
                <div className="header heading">Transactions</div>
                <div className="routes">
                  <div className="route">https://sandbox.withbrank.com/transactions</div>
                  <Button
                    isLoading={loading === 'transactions'}
                    action={() => toggleView('transactions')}
                    size="sm"
                  >
                    Get Transactions
                  </Button>
                </div>

                {show.transactions && (
                  <DataTable columns={transactionColumns} data={data?.transactions?.transactions} />
                )}
              </div>

              <div className="data-table">
                <div className="header heading">Balance</div>
                <div className="routes">
                  <div className="route">https://sandbox.withbrank.com/balances</div>
                  <Button
                    isLoading={loading === 'balances'}
                    action={() => toggleView('balances')}
                    size="sm"
                  >
                    Get Balances
                  </Button>
                </div>

                {show.balances && <DataTable columns={balanceColumns} data={data?.balances} />}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default App;
