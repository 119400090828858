import React from 'react';
import { TableRow } from './TableRow';
import Cell from './Cell';
import Flex from './Flex';
import theme from '../theme';
import Loader from './Loader';

const Separator = ({ gap = 16, styles, color = theme.colors.gray[100], className }) => {
  return (
    <div
      className={`separator ${className}`}
      css={{
        height: '1px',
        background: color,
        margin: `${gap}px 0`,
        width: '100%',
        ...styles,
      }}
    ></div>
  );
};

const DataTable = ({
  title,
  columns,
  data = [],
  actions,
  isLoading = false,
  className,
  hasBorder = true,
  showHeadings = true,
  onRowSelect,
  showHeader = true,
}) => {
  const headings = columns?.map((column) => {
    return column.title;
  });

  return (
    <div
      css={{
        width: '100%',
        background: theme.colors.white,
      }}
      className={className}
    >
      {showHeader && (
        <Flex css={{ paddingBottom: 20 }} jc="space-between" ai="center">
          {title && (
            <Flex className="title-container">
              <h4
                css={{
                  color: theme.colors.secondary,
                  fontSize: 18,
                  fontFamily: theme.typography.fonts.sans,
                  fontWeight: 600,
                }}
              >
                {title}
              </h4>
            </Flex>
          )}
        </Flex>
      )}

      <Separator gap={0} className="header-seperator" />
      {!isLoading && data.length > 0 && (
        <div>
          <div css={{ maxWidth: '100%' }}>
            <table
              css={{
                borderSpacing: '0px',
                width: '100%',
                borderCollapse: 'collapse',
              }}
            >
              {showHeadings && (
                <thead>
                  <tr
                    css={{
                      borderBottom: `1px solid ${theme.colors.gray[100]}`,
                    }}
                  >
                    {headings?.map((heading, index) => {
                      return (
                        <Cell
                          css={{
                            borderBottom: `1px solid ${theme.colors.gray[100]}`,
                            paddingLeft: '0',
                          }}
                          index={hasBorder ? index : 6}
                          key={index}
                          isHeader={true}
                        >
                          {heading}
                        </Cell>
                      );
                    })}
                    {actions && <Cell isHeader>Actions</Cell>}
                  </tr>
                </thead>
              )}

              <tbody>
                {data.map((item, index) => {
                  return (
                    <TableRow
                      onClick={onRowSelect ? () => onRowSelect(item) : () => {}}
                      key={index}
                      css={{
                        td: {
                          paddingLeft: '0',
                        },
                      }}
                    >
                      {columns?.map((column, index) => {
                        if (column.render) {
                          return (
                            <Cell
                              span={column.span}
                              styles={column?.cellStyle}
                              index={hasBorder ? index : 6}
                              key={index}
                            >
                              {column.render(item[column.dataIndex], item)}
                            </Cell>
                          );
                        }
                        return (
                          <Cell
                            span={column.span}
                            styles={column?.cellStyle}
                            index={index}
                            key={index}
                          >
                            {item[column.dataIndex]}
                          </Cell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Separator gap={0} />
        </div>
      )}

      {isLoading && (
        <Flex ai="center" jc="center" css={{ height: '200px' }}>
          <Loader
            size="small"
            color={theme.colors.secondary}
            scheme="light"
            label={`Loading ${title?.toLowerCase()}`}
          />
        </Flex>
      )}
    </div>
  );
};

export default DataTable;
