import { css, Global } from '@emotion/react';
import theme from './index';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Playfair+Display&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          letter-spacing: 0.2px;
          font-weight: 350;
          font-family: 'Inter', sans-serif;
          -webkit-font-smoothing: antialiased;
        }

        .heading {
          font-family: 'Playfair Display', serif;
        }

        .header {
          margin-bottom: 10px;
        }

        .logo {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          box-shadow: ${theme.shadows.md};
          padding: 10px;
          margin-bottom: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .sub-header {
          margin-bottom: 20px;
          font-size: 19px;
          color: ${theme.colors.gray[500]};
        }

        .description {
          font-size: 16px;
          color: ${theme.colors.gray[700]};
          font-weight: 300;
        }

        .separator {
          height: 1px;
          width: 100%;
          background-color: ${theme.colors.gray[200]};
          margin: 20px 0;
        }

        .card {
          margin-top: 30px;
        }

        .data-table {
          margin: 20px 0;
          border-radius: 6px;
          border: 1px solid ${theme.colors.gray[200]};
          overflow: hidden;

          .header {
            padding: 20px;
            background-color: ${theme.colors.coolGray[100]};
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }

          .routes {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .route {
              font-family: 'Source Code Pro', monospace;
              font-size: 14px;
              word-spacing: 1.3px;
              color: white;
              background-color: ${theme.colors.blueGray[600]};
              padding: 3px 6px;
            }
          }
        }

        .tag {
          height: 24px;
          padding: 0 12px;
          border-radius: 6px;
          background-color: ${theme.colors.green[100]};
          font-size: 12px;
          text-transform: uppercase;
          color: ${theme.colors.green[600]};
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }

        html {
          scroll-behavior: smooth;
        }

        li {
          list-style: none;
        }

        .container-large {
          max-width: 960px;
          margin-right: auto;
          margin-left: auto;
          padding: 80px 40px;
        }

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${theme.colors.grey} inset !important;
        }

        a {
          text-decoration: none;
          color: var(--primary-green);
          font-size: 14px;
        }
      `}
    />
  );
};

export default GlobalStyles;
