import * as React from 'react';

const Flex = ({
  tabIndex,
  children,
  ai = '',
  jc = '',
  stack = false,
  className,
  onClick,
  styles,
  onBlur,
  id,
  ...rest
}) => {
  return (
    <div
      id={id}
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      className={className}
      css={{
        display: 'flex',
        flexDirection: stack ? 'column' : 'row',
        alignItems: ai,
        justifyContent: jc,
        cursor: 'pointer',
        ...styles,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
