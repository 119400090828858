import { keyframes } from '@emotion/react';

export const scaleUp = keyframes`
  from {
    opacity:0;
    transform:scale(0.95);
  }
  to{
    opacity: 1;
    transform:scale(1.0);
  }
`;

export const scaleDown = keyframes`
  from {
    opacity:0;
    transform:scale(1.05);
  }
  to{
    opacity: 1;
    transform:scale(1.0);
  }
`;

export const fadeIn = keyframes`
from {
  opacity:0;
}
to{
  opacity: 1;
}
`;

export const slideInFromRight = keyframes`
  from {
    right: 0;
    opacity:0.5;
  }

  to {
    right: 50px;
    opacity: 1;
  }
`;

export const progress = keyframes`
  from{
    transform: translateX(-100%)
  }
  to {
    transform: translateX(0)
  }

`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { scaleUp, scaleDown, fadeIn, slideInFromRight, progress };
